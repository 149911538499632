<template>
  <div class="home">
<!--    <elMenu></elMenu>-->
    <img src="../../../public/images/eye4/aa.png" width="100%">
    <!--    content1-->
    <div class="content1">
      <div class="content1_1">
        <div class="content1_1_tite">关于VeePai云平台</div>
        <div class="content1_1_2">
          VeePai云平台始创于2015年，是国内领先的智能视频产品云存储平台，为190+国家和地区企业、开发者等机构提供稳定可靠、安全可信、持续创新的云服务，推进实现“0门槛、低费用、安全可靠”的普惠方针。
          为中小型企业提供应对互联网业务云基础解决方案，帮助中小型企业快速转型升级。截至2018年底，VeePai云共上线180+个云产品服务，与30000+的伙伴合作、超200w+名开发者。
          <p>VeePai云通过构建统一运维平台，可以为企业快速提供智能化运维能力，解决企业智能化单品多模块联动问题。</p>
          <div>
            <img src="../../../public/images/eye4/time.jpg">
          </div>
        </div>
      </div>
      <div class="content1_2">
        <div>
          <img src="../../../public/images/eye4/yun.png">
        </div>
        <div class="content1_2_right">
          <div>
            VeePai云设计之初，便以满足客户多样化需求为核心，提供优质云服务为基础，打造智能终端产品的一站式服务平台。
            为企业提供全操作系统API接口，实现多平台、跨品牌及通讯协议之间相互联动，帮助企业实现一程序多产品互联（一款App应用，可控制多款智能硬件产品）。多功能模块化，为企业提供多种选择，加速企业软硬件智能化整合升级。
          </div>
          <div class="content1_2_right_top">
            VeePai云——“站在巨人肩膀看世界”。覆盖中国、美国、欧洲、澳洲、中东等全球190+国家地区，平均10ms服务器响应时间，超千万级云付费用户群体。解决企业智能化升级、全球化发展等问题，为提供企业更全面的解决方案。
          </div>
        </div>
      </div>
    </div>
    <!--    content2-->
    <div class="content2">
      <div class="content1_1_tite">获得荣誉</div>
      <el-carousel :interval="4000" type="card" height="400px">
        <el-carousel-item>
          <img src="../../../public/images/eye4/honor/1_1.png" width="262" height="364">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../../public/images/eye4/honor/1_2.png" width="262" height="364">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../../public/images/eye4/honor/1.png" width="262" height="364">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../../public/images/eye4/honor/2.png" width="262" height="364">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../../public/images/eye4/honor/3.png" width="262" height="364">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../../public/images/eye4/honor/4.png" width="262" height="364">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../../public/images/eye4/honor/5.png" width="262" height="364">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../../public/images/eye4/honor/6.png" width="262" height="364">
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--    content3-->
    <div class="content3">
      <div class="content1_1_tite">最新案例</div>
      <div class="content3_box">
        <div class="content3_box_1">
          <img src="../../../public/images/eye4/case/1.jpg" width="100%">
          <div class="content3_box_sise">小智家APP</div>
        </div>
        <div class="content3_box_1">
          <img src="../../../public/images/eye4/case/2.jpg" width="100%">
          <div class="content3_box_sise"> FEIT APP</div>
        </div>
        <div class="content3_box_1">
          <img src="../../../public/images/eye4/case/3.jpg" width="100%">
          <div class="content3_box_sise">小智驹APP</div>
        </div>
        <div class="content3_box_1">
          <img src="../../../public/images/eye4/case/4.jpg" width="100%">
          <div class="content3_box_sise">智能云APP</div>
        </div>
      </div>
    </div>
    <!--    content4-->
    <div class="content4">
      <img src="../../../public/images/eye4/foot.png" width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'

export default {
  components: {
    elMenu,foot,navigation
  },
}
</script>

<style scoped>

.content1 {
  margin: 0px auto;
  height: 1080px;
}

.content1_1 {
  width: 1360px;
  margin: 0px auto;
  height: 560px;
}

.content1_1_tite {
  text-align: center;
  font-size: 32px;
  padding-top: 60px;
}

.content1_1_2 {
  text-indent: 2em;
  padding-top: 60px;
  line-height: 35px;
  font-size: 20px;
}

.content1_2 {
  padding-top: 80px;
  display: flex;
  width: 1360px;
  margin: 0px auto;
  height: 560px;
}

.content1_2_right {
  text-indent: 2em;
  margin: 20px;
  line-height: 30px;
  font-size: 18px;
}

.content1_2_right_top {
  margin-top: 120px;
}

.content2 {
  width: 100%;
  height: 700px;
  margin: 30px auto;
  background-color: #f7f7f7;
  padding: 60px 0px 0px 0px;
}

/deep/ .el-carousel__container {
  margin-top: 100px;
  margin-left: 18%;
}

.content3 {
  width: 1360px;
  height: 650px;
  margin: 0px auto;
  padding: 100px 0px 0px 0px;
}

.content3_box {
  padding-top: 80px;
  display: flex;
  justify-content: space-around;
}

.content3_box_1 {
  width: 20%;
  height: 400px;
  border: 1px solid #ececec;
}
.content3_box_sise {
  line-height: 50px;
  text-align: center;
  font-size: 18px;
}
.content4
{
  margin-top: 10px;
}
</style>